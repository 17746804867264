.countryImg {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  display: inline-flex;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.5);
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  @media (--viewportLarge) {
    width: 25px;
    height: 25px;
  }
  & > img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}
.countryName {
  color: var(--colorWhite);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-right: 16px;
  @media (--viewportLarge) {
    font-size: 15px;
    line-height: 22px;
    margin-right: 22px;
  }
}
.menuContent {
  right: 0 !important;
  left: auto !important;
  padding: 10px;
  top: 100% !important;
  min-width: 100px !important;
  @media (--viewportMedium) {
    top: calc(100% + 10px) !important;
  }
  @media (--viewportLarge) {
    top: calc(100% + 15px) !important;
  }
  & > ul {
    & > li {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      & > button {
        display: flex;
        align-items: center;
        color: var(--colorGrey800);
        font-size: 13px;
        text-decoration: none !important;
        transition: var(--transitionStyleButton);
        @media (--viewportLarge) {
          font-size: 14px;
        }
        &:hover {
          color: var(--marketplaceColor);
          transition: var(--transitionStyleButton);
        }
        & > img {
          width: 20px;
          height: 20px;
          margin-right: 6px;
          border-radius: 100px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.localeContainer{
  margin-right: 24px;
}